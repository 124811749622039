import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { ProductDetailPage } from "./ProductDetailPage/ProductDetailPage";
import ImageViewer from "react-simple-image-viewer";
import TypewriterEffect from "./TypewriterEffect";
import { Interweave } from "interweave";
import GoodResp from "./Svg/GoodResp";
import BadResp from "./Svg/BadResp";
import ImageNotFound from "./Svg/ImageNotFound";
import GoodRespClicked from "./Svg/GoodRespClicked";
import BadRespClicked from "./Svg/BadRespClicked";
import Loader from "./Loader/Loader";
import { CONSTANTS } from "../Constants/Constants";
import DocumentIcon from "./Svg/DocumentIcon"; // Added import for DocumentIcon

export const ChatMessages = ({
  message,
  setInputMessage,
  widgetInfo,
  scrollRef,
  isFullScreen,
  setFocus,
  isFetching,
  threadInfos,
  showInitialMessages,
  typedMsgsIds,
  setTypedMsgsIds,
  postRating,
  removeTooltip,
  fileInputRef,
  handleAddMessage
}) => {
  const [showImagePage, setShowImagePage] = useState("");
  const [singleImageData, setsingleImageData] = useState({});
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [images, setImages] = useState([]);
  const ref = useRef(null);

  const [mouseDown, setMouseDown] = useState(false);
  const [clickDuringDrag, setClickDuringDrag] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  const [isFeedbackClicked, setIsFeedbackClicked] = useState(3);
  const [shouldAutoScroll, setShouldAutoScroll] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      if (scrollRef.current) {
        const isNearBottom = scrollRef.current.scrollHeight - scrollRef.current.scrollTop <= scrollRef.current.clientHeight + 100; // 100px threshold
        setShouldAutoScroll(isNearBottom);
      }
    };

    if (scrollRef.current) {
      scrollRef.current.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (scrollRef.current) {
        scrollRef.current.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  useEffect(() => {
    if (shouldAutoScroll && !mouseDown && ref.current) { // Disable auto-scroll when the user is actively scrolling
      ref.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
    }
  }, [message.length, shouldAutoScroll, mouseDown]);

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  const getClassName = (type) => {
    if (type === "assistant" || type === "human") {
      return "lcb_chat-bubble-sender";
    } else {
      return "lcb_chat-bubble-reciever";
    }
  };

  const startDragging = (e) => {
    setMouseDown(true);
    setStartX(e.pageX - e.currentTarget.offsetLeft);
    setScrollLeft(e.currentTarget.scrollLeft);
    setClickDuringDrag(false);
  };

  const stopDragging = () => {
    setMouseDown(false);
    if (clickDuringDrag) {
      setClickDuringDrag(true);
    }
  };

  const move = (e) => {
    e.preventDefault();
    if (!mouseDown) return;

    const x = e.pageX - e.currentTarget.offsetLeft;
    const scroll = x - startX;
    e.currentTarget.scrollLeft = scrollLeft - scroll;
    setClickDuringDrag(true);
  };

  const renderMessages = (messages = []) => {
    return messages.map(
      (
        {
          humanJoined,
          label,
          contents,
          role,
          metadata,
          is_finished,
          id,
          initials,
        },
        index
      ) => {
        return (
          <Fragment key={id}>
            {role === "assistant" || role === "human" ? (
              role === "human" && humanJoined ? (
                <div className="lcb_humanJoinedContainer">
                  <p className="lcb_lineForNewJoined"></p>
                  <p className="lcb_labelForNewJoined">{label}</p>
                </div>
              ) : (
                <Fragment>
                  {metadata?.data?.length > 0 &&
                    metadata?.type === "images" && (
                      <div className="lcb_sender-container">
                        <div className="lcb_user-avatar">
                          <img
                            src={"https://imagedelivery.net/5lD6UX1XAYBz_50pGg1VKg/855e3a71-9279-4b10-37f8-ef0d6026d800/w=100"}
                            alt="user-avatar"
                            width={"40px"}
                          />
                        </div>
                        {metadata?.type === "images" && (
                          <div className="lcb_wrapperOfBotInitials">
                            {metadata?.data?.length > 0 && (
                              <div
                                className="lcb_image-container lcb_chat-bubble"
                                style={{ marginBottom: 16 }}
                              >
                                {metadata?.data.map((curr, idx) => (
                                  <div
                                    key={idx}
                                    className="lcb_image-wrapper"
                                    onClick={() => {
                                      let img_arr = [...images];
                                      if (!images.includes(curr.image_url)) {
                                        img_arr = [...images, curr.image_url];
                                      }
                                      setImages(img_arr);
                                      openImageViewer(
                                        img_arr.findIndex(
                                          (item) => item === curr.image_url
                                        )
                                      );
                                    }}
                                  >
                                    <img
                                      src={curr.image_url}
                                      alt={curr.image_alt}
                                    />
                                    <h6
                                      className="lcb_img-title"
                                      style={{ textAlign: "center" }}
                                    >
                                      {curr.image_title}
                                    </h6>
                                  </div>
                                ))}
                                {isViewerOpen && (
                                  <ImageViewer
                                    src={images}
                                    currentIndex={currentImage}
                                    onClose={closeImageViewer}
                                    disableScroll={false}
                                    backgroundStyle={{
                                      backgroundColor: "rgba(0,0,0,0.9)",
                                    }}
                                    closeOnClickOutside={true}
                                  />
                                )}
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    )}
                  {metadata?.type === "products" && (
                    <>
                      <div
                        className="lcb_sender-container"
                        style={{ padding: 10 }}
                      >
                      <div className="lcb_user-avatar">
                        <img
                          src={"https://imagedelivery.net/5lD6UX1XAYBz_50pGg1VKg/855e3a71-9279-4b10-37f8-ef0d6026d800/w=100"}
                          alt="user-avatar"
                          width={"40px"}
                        />
                        </div>
                        <div className="lcb_wrapperOfBotInitials">
                          {metadata?.data.length > 0 && (
                            <div
                              className="lcb_image-container lcb_chat-bubble"
                              style={{
                                marginBottom: 16,
                                borderRadius: 0,
                                padding: 10,
                              }}
                              onMouseDown={startDragging}
                              onMouseUp={stopDragging}
                              onMouseMove={move}
                              onMouseLeave={stopDragging}
                            >
                              {metadata?.data.map((curr, idx) => (
                                <div
                                  key={idx}
                                  className="lcb_image-wrapper"
                                  onClick={(e) => {
                                    if (clickDuringDrag) {
                                      e.preventDefault();
                                      return;
                                    } else {
                                      setShowImagePage(curr.name);
                                      setsingleImageData(curr);
                                    }
                                  }}
                                >
                                  {typeof curr === "object" && (
                                    <>
                                      {curr?.images[0]?.url ? (
                                        <img
                                          src={curr?.images[0].url}
                                          alt={curr?.images[0]?.alt}
                                        />
                                      ) : (
                                        <ImageNotFound />
                                      )}
                                      <h6 className="lcb_img-title">
                                        {curr.name}
                                      </h6>
                                      <h6 className="lcb_img-text">
                                        <Interweave
                                          content={curr.short_description}
                                        />
                                      </h6>
                                    </>
                                  )}
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      </div>
                    </>
                  )}

                  <div className="lcb_sender-container">
                    <div
                      style={{
                        visibility:
                          metadata?.type === "images" ? "hidden" : "visible",
                      }}
                      className="lcb_user-avatar"
                    >
                     <img
                        src={"https://imagedelivery.net/5lD6UX1XAYBz_50pGg1VKg/855e3a71-9279-4b10-37f8-ef0d6026d800/w=100"}
                        alt="user-avatar"
                        width={"40px"}
                      />
                    </div>

                    <p className={`lcb_chat-bubble ${getClassName(role)}`}>
                      {/* AI's messages which render in typerighter manner */}
                      {!typedMsgsIds.includes(id) || !is_finished ? (
                        <TypewriterEffect
                          htmlString={contents}
                          msgId={id}
                          typedMsgsIds={typedMsgsIds}
                        />
                      ) : (
                        <Interweave content={contents} />
                      )}
                      {is_finished &&
                        index === messages.length - 1 &&
                        messages.length > 1 && (
                          <section className="lcb_chat-feedback-section">
                            <span
                              data-tooltip-id="lcb_my-tooltip"
                              data-tooltip-content={
                                CONSTANTS.TOOLTIPS.goodFeedback
                              }
                              onClick={() => {
                                postRating(id, true);
                                removeTooltip();
                                setIsFeedbackClicked(5);
                              }}
                            >
                              {isFeedbackClicked === 5 ? (
                                <GoodRespClicked />
                              ) : (
                                <GoodResp />
                              )}
                            </span>
                            <span
                              data-tooltip-id="lcb_my-tooltip"
                              data-tooltip-content={
                                CONSTANTS.TOOLTIPS.badFeedback
                              }
                              onClick={() => {
                                postRating(id, false);
                                removeTooltip();
                                setIsFeedbackClicked(0);
                              }}
                            >
                              {isFeedbackClicked === 0 ? (
                                <BadRespClicked />
                              ) : (
                                <BadResp />
                              )}
                            </span>
                          </section>
                        )}
                    </p>
                  </div>
                  {metadata?.data?.length > 0 &&
                    metadata?.type !== "images" && (
                      <div
                        className="lcb_sender-container"
                        style={{
                          padding: 10,
                          display: (metadata?.type === "options" || metadata?.options?.length > 0) ? "none" : "",
                        }}
                      >
                        {metadata?.type === "links" && (
                          <div className="lcb_wrapperOfBotInitials">
                            {metadata?.data.map((s) => {
                              return (
                                <a
                                  key={s.title}
                                  className="lcb_botLinks"
                                  target={s.target}
                                  href={s.url}
                                >
                                  {s.title}
                                </a>
                              );
                            })}
                          </div>
                        )}
                        {metadata?.type === "upload_btn" && metadata?.data?.length > 0 && (
                          <div className="lcb_buttons-container">
                          <button className="lcb_cta-button" onClick={() => fileInputRef.current.click()}>{metadata.data}</button>
                          </div>
                        )}
                      </div>
                    )}

                </Fragment>
              )
            ) : (
              <div key={id} className={`lcb_chat-bubble ${getClassName(role)}`}>
                {metadata?.data && metadata?.data[0]?.image_url && (
                  <div className="lcb-uploaded-img">
                    <img src={metadata?.data[0]?.image_url} />
                  </div>
                )}
                {metadata?.type === "documents" && (
                  <div className="lcb_sender-container document">
                    <div className="lcb_wrapperOfBotInitials">
                      {metadata?.data?.length > 0 && (
                        <div
                          className="lcb_document-container lcb_chat-bubble"
                          style={{ marginBottom: 16 }}
                        >
                          {metadata?.data.map((doc, idx) => (
                            <div
                              key={idx}
                              className="lcb_document-wrapper"
                            > <a
                                href={doc.document_url}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="lcb_document-link"
                              >
                              <DocumentIcon />

                                <p className="lcb_document-title">
                                  {doc.filename}
                                </p>
                              </a>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                )}
                <p>{contents}</p>
              </div>
            )}
          </Fragment>
        );
      }
    );
  };

  return (
    <>
      {!showImagePage && (
        <div className={"lcb_message-container"} ref={scrollRef}>
          <div>
            {threadInfos.length > 0 ? (
              threadInfos.map((s, index) => {
                return (
                  <Fragment key={s?.id}>
                    <div className="lcb_humanJoinedContainer">
                      <p className="lcb_lineForNewJoined"></p>
                      <p className="lcb_labelForNewJoined">{s.title}</p>
                    </div>

                    {index !== threadInfos.length - 1 &&
                      renderMessages(s.messages)}
                  </Fragment>
                );
              })
            ) : (
              <Loader />
            )}
            {/* Render current messages */}
            {renderMessages(message)}
          </div>

          <div>
            {/* Initial ask by BOT */}
            {showInitialMessages && showInitialMessages?.length > 0 && (
              <div className="lcb_convoByBotWrapper" style={{ marginTop: 30 }}>
                {/* <p className="lcb_askQue">
                  <img
                    src={
                      "https://agent00xx.github.io/lastbotv2/assets/Images/Askanythinginany.png"
                    }
                    alt="user-avatar"
                    width={"274px"}
                  />
                </p> */}
                {showInitialMessages.map((s) => (
                  <p
                    className="lcb_botInitialMessage"
                    key={s}
                    onClick={() => handleAddMessage(s)}
                  >
                    {s}
                  </p>
                ))}
              </div>
            )}

            {isFetching && <Loader />}

            <div className="lcb_to-scroll-div" ref={ref} />
          </div>
        </div>
      )}
      {showImagePage && (
        <ProductDetailPage
          setShowImagePage={setShowImagePage}
          singleImageData={singleImageData}
          isFullScreen={isFullScreen}
        />
      )}
    </>
  );
};

