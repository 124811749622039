import React, { useState } from "react";
import CrossIcon from "./Svg/CrossIcon";
import FileIcon from "./Svg/FileIcon";
import SendIcon from "./Svg/SendIcon";
import useDiffLang from "../contexts/langContext";
import LoadingIcons from 'react-loading-icons'

export const InputMessage = ({
  showError,
  showConsentMsg,
  handleAddMessage,
  inputMessage,
  setInputMessage,
  isFullScreen,
  htmlElRef,
  isFetching,
  fileInputRef,
}) => {
  const [prevImg, setPrevImg] = useState(false);
  const [prevImgSrc, setPrevImgSrc] = useState("");
  const [isUploading, setIsUploading] = useState(false); // State to track upload status


  const { defaultLang } = useDiffLang();

  const onSubmitHandler = (e) => {
    e?.preventDefault();
    if (inputMessage || prevImgSrc) {
      setIsUploading(true); // Start upload simulation
      handleAddMessage(inputMessage, prevImgSrc);
        setInputMessage("");
        closeImgPrv(); // This now also resets the file input field
        htmlElRef.current.style.height = "44px";
      // Simulate an upload process
      setTimeout(() => {

        setIsUploading(false); // End upload simulation
      }, 3000); // Adjust the timeout to simulate upload time
    }
  };

  const previewPhoto = (e) => {
    setPrevImg(true);
    const file = e.target.files[0];
    if (file) {
      setPrevImgSrc(file);
      if (!inputMessage) {
        setInputMessage(""); // Set input message to space if empty when a file is selected
      }
      // Focus the input field when a photo is previewed
      htmlElRef.current.focus();
    }
  };
  const closeImgPrv = () => {
    setPrevImg(false);
    setPrevImgSrc("");
    document.getElementById("file-input").value = ""; // Reset the file input field
  };

  const calculateHeight = () => {
    if (htmlElRef.current) {
      htmlElRef.current.style.height = "auto";
      htmlElRef.current.style.height = `${htmlElRef.current.scrollHeight - 8}px`;
    }
  };



  const handleKeyDown = (event) => {
    if (event.key === "Enter" && event.keyCode === 13 && !event.shiftKey) {
      event.preventDefault();
      onSubmitHandler(event);
    }
  };

  return (
    <div className="lcb_input-message">

      {prevImg && prevImgSrc && (
        <div
          className={`lcb_img-prev ${
            isFullScreen && "lcb_img-prev-full-screen"
          }`}
        >
          <span className="cross-icon" onClick={closeImgPrv}>
            <CrossIcon />
          </span>
          <img src={URL?.createObjectURL(prevImgSrc)} />
        </div>
      )}
      <form
        onSubmit={onSubmitHandler}
        className="lcb_inputText"
        style={{
          marginBottom:
            showConsentMsg && !localStorage.getItem("lcb-consent-msg-shown")
              ? 0
              : 12,
        }}
      >
        {isUploading ? (
          <div className="lcb_uploading-indicator">
            <LoadingIcons.TailSpin stroke="currentColor"/> {/* Display the spinner icon while uploading */}
          </div>
        ) : (
          <label className="lcb_attachFile" htmlFor="file-input">
            <input
              disabled={isFetching || showError}
              ref={fileInputRef}
              id="file-input"
              type="file"
              accept="image/png, image/gif, image/jpeg, application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document, text/csv"
              onChange={previewPhoto}
            />
            <FileIcon />
          </label>
        )}
        <textarea
          placeholder={defaultLang.send_msg}
          onChange={(e) => {
            setInputMessage(e.target.value);
          }}
          onKeyDown={handleKeyDown}
          name="message"
          ref={htmlElRef}
          disabled={isFetching || showError}
          value={inputMessage}
          onInput={calculateHeight}
          style={{
            margin:
              showConsentMsg && !localStorage.getItem("lcb-consent-msg-shown")
                ? "10px 3px"
                : "16px 10px 16px 3px",
          }}
        ></textarea>
          <button
            type="submit"
            className={`lcb_sendButton ${isFullScreen ? "lcb_manageIcon" : ""} ${inputMessage || prevImgSrc ? "highlight" : ""}`} // Highlight the submit button when input field is not empty or an image is selected
            style={{
              padding:
                showConsentMsg && !localStorage.getItem("lcb-consent-msg-shown")
                  ? "3px"
                  : "7px 8px",
            }}
            disabled={isFetching}
          >
            <SendIcon />
          </button>
      </form>
      {showConsentMsg && !localStorage.getItem("lcb-consent-msg-shown") && (
        <div
          className={`lcb-consent-msg ${isFullScreen ? "in-full-screen" : ""}`}
        >
          <span>{defaultLang.consent}</span>
        </div>
      )}
    </div>
  );
};
