import React, { useState, useEffect } from "react";
import { Interweave } from "interweave";

// Helper component to render html string in typewriter effect
function TypewriterEffect({ htmlString, msgId, typedMsgsIds }) {
  const [displayedContent, setDisplayedContent] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (currentIndex < htmlString.length) {
        setDisplayedContent(htmlString.substring(0, currentIndex + 1));
        setCurrentIndex(currentIndex + 1);
      }
    }, 5); // Adjust the delay (in milliseconds) for typing speed

    return () => {
      clearTimeout(timer);
      let newTypedMsgsIds = typedMsgsIds;
      if (!typedMsgsIds.includes(msgId)) {
        newTypedMsgsIds.push(msgId);
      }
      localStorage.setItem(
        "lastbot-widget-msg-ids",
        JSON.stringify(newTypedMsgsIds)
      );
    };
  }, [currentIndex, htmlString]);

  return (
    <div>
      <Interweave content={displayedContent} />
    </div>
  );
}

export default TypewriterEffect;
