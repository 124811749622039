import React, { useEffect, useRef, useState } from "react";
import { ChatHeader } from "./ChatHeader";
import { ChatMessages } from "./ChatMessages";
import { InputMessage } from "./InputMessage";
import { generateRandomString } from "../utils/helper";
import ErrorDiv from "./ErrorDiv/ErrorDiv";
import { Tooltip } from "react-tooltip";
import useTheme from "../contexts/darkThemeContext";
import useDiffLang from "../contexts/langContext";
import { LOCALE_STRINGS } from "../Constants/Constants";

// Check message is in JSON formate or not (For Error)
function isJson(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return JSON.parse(str);
}

export const ChatContainer = () => {
  const scrollRef = useRef();
  const [uuid, setUuid] = useState("");
  const [sessionId, setSessionId] = useState("");
  const [threadInfos, setThreadInfos] = useState([]);
  const [typedMsgsIds, setTypedMsgsIds] = useState([]);
  const [currentUserId, setCurrentUserId] = useState("");

  const [BASE_URL, setBaseUrl] = useState("");

  const [isFetching, setIsFetching] = useState(false); // Flag to control polling

  const [showChat, setShowChat] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [message, setMessage] = useState([]);

  const [inputMessage, setInputMessage] = useState("");
  const [widgetInfo, setWidgetInfo] = useState(null);
  const [showInitialMessages, setShowInitialMessages] = useState([]);

  const [showError, setShowError] = useState(false);
  const [showConsentMsg, setshowConsentMsg] = useState(true);
  const [threadInterval, setThreadInterval] = useState(null);

  const [locale, setLocale] = useState("");


  const htmlElRef = useRef(null);
  const { isDarkTheme } = useTheme();
  const { changeLang } = useDiffLang();
  const { defaultLang } = useDiffLang();
  const fileInputRef = useRef(null);

  useEffect(() => {
    setTimeout(() => {
      const { autoOpen, baseUrl } = window.lastbotSettings;
      setBaseUrl(baseUrl);

      // to open chatBot autometically open after 5 seconds if auto open is true
      if (autoOpen) {
        setTimeout(() => {
          setShowChat(true);
        }, 5000);
      }
    }, 0);
  }, []);

  useEffect(() => {
    if (!!BASE_URL && threadInfos.length === 0) {
      const createdSessionId = createSessionId();
      let localStorageUuid = localStorage.getItem("uuid");
      getWidget(createdSessionId, localStorageUuid);
    }
  }, [BASE_URL]);

  useEffect(() => {
    if (showChat && htmlElRef.current) {
      htmlElRef.current.focus();
    }
  }, [showChat]);

  useEffect(() => {
    const interval = setInterval(() => {
      getMessages(sessionId, uuid, currentUserId);
    }, 1000); // Fetch every second

    // Clear the interval and allow fetching when component unmounts
    return () => clearInterval(interval);
  }, [isFetching]);

  useEffect(() => {
    if (showChat) {
      const { userId } = window.lastbotSettings;

      const createdUuid = createUserId(userId);

      const createdSessionId = createSessionId();
      createNewMsgThread(createdSessionId, createdUuid);
    }

    if (!showChat) {
      if (threadInterval) clearInterval(threadInterval);
    }
  }, [showChat]);

  useEffect(() => {
    if (!isFetching) {
      htmlElRef.current && htmlElRef.current.focus();
    }
  }, [isFetching]);

  const createNewMsgThread = (SessionId, Uuid) => {
    if (threadInfos.length === 0) {
      getMessageThreads(SessionId, Uuid);
    }

    const interval2 = setInterval(() => {
      getMessageThreads(SessionId, Uuid, true);
    }, 30000); // Fetch every 30 second
    setThreadInterval(interval2);

    // Clear the interval and allow fetching when component unmounts
    return () => {
      if (threadInterval) clearInterval(threadInterval);
    };
  };

  const createUserId = (userId) => {
    let localStorageUuid = localStorage.getItem("uuid");

    if (localStorageUuid) {
      setUuid(localStorageUuid);
      return localStorageUuid;
    }
    // If userId is set by client
    else if (userId) {
      setUuid(userId);
      localStorage.setItem("uuid", userId);
      return userId;
    } else {
      const newUuid = generateRandomString();
      localStorage.setItem("uuid", newUuid);
      setUuid(newUuid);
      return newUuid;
    }
  };

  const createSessionId = () => {
    let localStorageSessionId = sessionStorage.getItem("sessionId");

    if (localStorageSessionId) {
      setSessionId(localStorageSessionId);
      return localStorageSessionId;
    } else {
      const newSessionId = generateRandomString();
      sessionStorage.setItem("sessionId", newSessionId);
      setSessionId(newSessionId);
      return newSessionId;
    }
  };

  const resetChat = () => {
    localStorage.setItem("lastbot-widget-msg-ids", []);
    setMessage([]);
    setThreadInfos([]);
    setShowInitialMessages([]);
    const { userId } = window.lastbotSettings;
    const createdUuid = createUserId(userId);
    sessionStorage.clear();
    const createdSessionId = createSessionId();
    setTypedMsgsIds([]);
    setIsFetching(false); // Set isFetching to false on reset
    if (threadInterval) clearInterval(threadInterval);
    getMessageThreads(createdSessionId, createdUuid);
    createNewMsgThread(createdSessionId, createdUuid);
  };

  // Doing for Open any links in New Tab
  const setTargetBlank = (originalString) => {
    let searchString = "<a";
    let addition = ' target="_blank" style="line-break: anywhere"';

    let currentIndex = originalString.indexOf(searchString);
    let modifiedString = "";

    while (currentIndex !== -1) {
      let substring = originalString.substring(currentIndex);
      let hrefIndex = substring.indexOf('href="');

      if (hrefIndex !== -1) {
        hrefIndex += 6; // Move to start of URL
        let closeQuoteIndex = substring.indexOf('"', hrefIndex);

        if (closeQuoteIndex !== -1) {
          let hrefValue = substring.substring(hrefIndex, closeQuoteIndex);

          if (
            hrefValue.startsWith("https://") ||
            hrefValue.startsWith("http://")
          ) {
            modifiedString +=
              originalString.substring(0, currentIndex + searchString.length) +
              addition;
          } else {
            modifiedString += originalString.substring(
              0,
              currentIndex + searchString.length
            );
          }

          originalString = originalString.substring(
            currentIndex + searchString.length
          );
          currentIndex = originalString.indexOf(searchString);
          continue;
        }
      }

      modifiedString += originalString.substring(
        0,
        currentIndex + searchString.length
      );
      originalString = originalString.substring(
        currentIndex + searchString.length
      );
      currentIndex = originalString.indexOf(searchString);
    }

    modifiedString += originalString; // Adding the remaining part of the original string

    return modifiedString;
  };

  const setFocus = () => {
    htmlElRef.current && htmlElRef.current.focus();
  };

  const removeTooltip = () => {
    setTimeout(() => {
      const eles = document.getElementsByClassName("react-tooltip__show");
      for(let i=0;i< eles.length;i++){
        eles[i].style.display = "none";
      }
    }, 10);
    // setTimeout(() => {
    //   const tooltip = document.getElementById("lcb_my-tooltip");
    //   if (tooltip){
    //     tooltip.style.display = "none";
    //   } ;
    // }, 100);
  };

  const setRenderMessages = (result, firstTime) => {
    result.map((item) => {
      item.contents = setTargetBlank(item.contents);
      return item;
    });

    result.forEach((item) => {
      if (item?.metadata?.type === "error") {
        setIsFetching(false); // Stop fetching if new messages are received
        setShowError(true);
      }
    });

    const lastMessage = result[result.length - 1];
    const optionsData = lastMessage.metadata?.type === "options" ? lastMessage.metadata?.data : lastMessage.metadata?.options;

    if (result.length > 0 && optionsData?.length > 0) {
      setShowInitialMessages(optionsData);
    }

    if (firstTime) {
      setMessage(result);
      if (!result[result.length - 1].is_finished) {
        setIsFetching(true);
      }
} else if (
      result[result.length - 1].role === "assistant" &&
      result[result.length - 1].is_finished
    ) {
      setIsFetching(false); // Stop fetching if new messages are received
      setMessage(result); // Update the messages state with new messages
      scrollRef.current.scrollTop = scrollRef.current?.scrollHeight;
    } else {
      setMessage(result); // Update the messages state with new messages
      // Check if the user is at the bottom of the chat
      const isUserAtBottom = scrollRef.current.scrollHeight - scrollRef.current.scrollTop <= scrollRef.current.clientHeight + 100;
      if (isUserAtBottom) {
        scrollRef.current.scrollTop = scrollRef.current?.scrollHeight;
      }
    }
  };

  const getWidget = (sessionId = null, uuid = null) => {
    let requestOptions = {
      method: "GET",
      redirect: "follow",
    };
    fetch(
      BASE_URL +
        `?url=${encodeURIComponent(window.location.href)}${
          sessionId ? `&session_id=${sessionId}` : ""
        }${uuid ? `&uuid=${uuid}` : ""}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setWidgetInfo(result);

        if (!!result.locale_strings) {
          changeLang(result.locale_strings);
        } else {
          changeLang(LOCALE_STRINGS);
        }

         // Set the locale state variable
         if (!!result.locale) {
          setLocale(result.locale);
        }
      })
      .catch((error) => console.log("error", error));
  };

  const getMessageThreads = (sessionId, uuid, polling = false) => {
    let requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(
      `${BASE_URL}/message_threads?session_id=${sessionId}&uuid=${uuid}&url=${
        encodeURIComponent(window.location.href)
      }${polling ? "&ping=true" : ""}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setThreadInfos(result);
        setCurrentUserId(result[result.length - 1].id);
        setRenderMessages(result[result.length - 1].messages, true);
        // getMessages(sessionId, uuid, result[0].id, true);
      })
      .catch((error) => console.log("error", error));
  };

  const getMessages = (sessionId, uuid, currentUserId) => {
    if (!isFetching) {
      return; // Stop fetching if a new message is already received
    }
    let requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(
      `${BASE_URL}/message_threads/${currentUserId}/messages?session_id=${sessionId}&uuid=${uuid}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setRenderMessages(result, false);
      })
      .catch((error) => console.log("error", error));
  };

  const postMessage = (inputFromUser, selectedFile) => {
    let myHeaders = new Headers();
    let formData = new FormData();

    formData.append("message[contents]", inputFromUser);

    if (selectedFile) {
      formData.append("message[attachment]", selectedFile);
    }

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formData,
      redirect: "follow",
    };

    fetch(
      `${BASE_URL}/message_threads/${currentUserId}/messages?session_id=${sessionId}&uuid=${uuid}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setMessage((prev) => [...prev, result]);
        setShowInitialMessages([]);
        setIsFetching(true);
        scrollRef.current.scrollTop = scrollRef.current?.scrollHeight;
      })
      .catch((error) => console.log("error", error));
  };

  const postRating = (messageId, isGood) => {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    let raw = JSON.stringify({
      rating: isGood ? 5 : 0,
    });

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
    };

    fetch(
      `${BASE_URL}/message_threads/${
        threadInfos[threadInfos.length - 1].id
      }/messages/${messageId}/rate`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        console.log("RATE SUCCESS.", result);
      })
      .catch((error) => console.log("RATE Failure error", error));
  };

  const handleAddMessage = (message, selectedFile) => {
    postMessage(message, selectedFile);
    if (showConsentMsg) {
      setshowConsentMsg(false);
      localStorage.setItem("lcb-consent-msg-shown", true);
    }
  };

  // disable scroll outside chat window
  const disableScroll = (e) => {
    document.body.style.overflow = "hidden";
  };

  // enable scroll outside chat window
  const enableScroll = (e) => {
    document.body.style.overflow = "auto";
    if (window.innerWidth < 450) {
      setShowChat(false);
    }
  };

  return (
    <div
      className="lbt_bot"
      onMouseEnter={disableScroll}
      onMouseLeave={enableScroll}
      lang={locale.language_code}
    >
      <div
        className={
          showChat
            ? isFullScreen
              ? `lcb_chat-container-desktopOpenView-fullscreen ${
                  isDarkTheme ? "lcb_dark-theme" : ""
                }`
              : window.innerWidth > 769
              ? `lcb_chat-container-desktopOpenView ${
                  isDarkTheme ? "lcb_dark-theme" : ""
                }`
              : `lcb_chat-container ${isDarkTheme ? "lcb_dark-theme" : ""}`
            : window.innerWidth > 769
              ?`lcb_chat-container-desktop ${
                isDarkTheme ? "lcb_dark-theme" : ""
              }`
              :`lcb_chat-container-desktop mobile ${
                isDarkTheme ? "lcb_dark-theme" : ""
              }`
        }
      >
        <Tooltip id="lcb_my-tooltip" style={{zIndex: '999'}}/>

        <ChatHeader
          threadInfos={threadInfos}
          setShowChat={setShowChat}
          showChat={showChat}
          isFullScreen={isFullScreen}
          setIsFullScreen={setIsFullScreen}
          widgetInfo={widgetInfo}
          resetChat={resetChat}
          enableScroll={enableScroll}
          disableScroll={disableScroll}
          removeTooltip={removeTooltip}
        />
        {showError && (
          <ErrorDiv setShowError={setShowError} resetChat={resetChat} />
        )}
        {showChat && (
          <>
            <ChatMessages
              scrollRef={scrollRef}
              isFullScreen={isFullScreen}
              message={message}
              setMessage={setMessage}
              inputMessage={inputMessage}
              setInputMessage={setInputMessage}
              widgetInfo={widgetInfo}
              setFocus={setFocus}
              isFetching={isFetching}
              threadInfos={threadInfos}
              showInitialMessages={showInitialMessages}
              typedMsgsIds={typedMsgsIds}
              setTypedMsgsIds={setTypedMsgsIds}
              postRating={postRating}
              removeTooltip={removeTooltip}
              fileInputRef={fileInputRef}
              handleAddMessage={handleAddMessage}
            />
            <InputMessage
              showError={showError}
              isFetching={isFetching}
              htmlElRef={htmlElRef}
              isFullScreen={isFullScreen}
              handleAddMessage={handleAddMessage}
              inputMessage={inputMessage}
              setInputMessage={setInputMessage}
              showConsentMsg={showConsentMsg}
              fileInputRef={fileInputRef}
            />
          </>
        )}
      </div>
    </div>
  );
};

