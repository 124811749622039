import "./Loader.css";

const Loader = () => {
  return (
    <div className="lcb_sender-container" style={{ alignItems: "center" }}>
      {/* <div className="lcb_user-avatar">{initials}</div> */}
      <div class="lcb_spinner">
        <div class="lcb_bounce1"></div>
        <div class="lcb_bounce2"></div>
        <div class="lcb_bounce3"></div>
      </div>
    </div>
  );
};

export default Loader;
