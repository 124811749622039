import { useEffect, useState } from "react";
import "./App.css";
import "./DarkMode.css";
import { ChatContainer } from "./component/ChatContainer";
import { DarkThemeProvider } from "./contexts/darkThemeContext";
import { LangChangeProvider } from "./contexts/langContext";

function App({ baseUrl, autoOpen, imgSrc, userId, darkMode }) {
  const [isDarkTheme, setIsDarkTheme] = useState(darkMode);
  const [defaultLang, setDefaultLang] = useState({});

  const toggleTheme = () => {
    setIsDarkTheme(!isDarkTheme);
  };

  const changeLang = (lang_obj) => {
    setDefaultLang(lang_obj);
  };

  useEffect(() => {
    // Check system dark mode
    // if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
    //   setIsDarkTheme(true);
    // }

    // Load Intercom script here
    window.lastbotSettings = {
      // settings we want to configure
      baseUrl,
      autoOpen,
      imgSrc,
      userId,
    };

    // Load the Intercom script
    (function () {
      var w = window;
      var ic = w.Intercom;
      if (typeof ic === "function") {
        ic("reattach_activator");
        ic("update", window.lastbotSettings);
      } else {
        var i = function () {
          i.c(arguments);
        };
        i.q = [];
        i.c = function (args) {
          i.q.push(args);
        };
        w.Intercom = i;
      }
    })();
  }, []);

  return (
    <LangChangeProvider value={{ defaultLang, changeLang }}>
      <DarkThemeProvider value={{ isDarkTheme, toggleTheme }}>
        <ChatContainer />
      </DarkThemeProvider>
    </LangChangeProvider>
  );
}

export default App;
